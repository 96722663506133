import React from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import Box from '@pagerland/common/src/components/Box';
import Grid from '@pagerland/common/src/components/Grid';
import { Gallery as PhGallery, Item } from 'react-photoswipe-gallery';

import { StyledImg } from './styled.components';
import data from '../../data';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

const Gallery = ({ name, photos, WrapperProps, GridProps, ImgProps }) => (
  <Box name={name} {...WrapperProps}>
    <PhGallery options={{ shareEl: false }}>
      <Grid {...GridProps}>
        {photos.map((item, i) => {
          return (
            <Item
              original={item.src}
              thumbnail={item.srcThumbnail}
              width="1024"
              height="768"
            >
              {({ ref, open }) => (
                <Fade key={i} bottom cascade duration={600} delay={i * 30}>
                  <StyledImg {...ImgProps} ref={ref} onClick={open} src={item.srcThumbnail} />
                </Fade>
              )}
            </Item>
          )
        })}
      </Grid>
    </PhGallery>
  </Box>
);

Gallery.propTypes = {
  name: PropTypes.string.isRequired,
  WrapperProps: PropTypes.object,
  GridProps: PropTypes.object,
  ImgProps: PropTypes.object,
};

Gallery.defaultProps = {
  ...data.gallery,
  GridProps: {
    gridTemplateColumns: {
      _: 'repeat(3, 1fr)',
      lg: 'repeat(5, 1fr)',
    },
  },
  ImgProps: {
    width: '100%',
    display: 'block',
  },
};

export default Gallery;
