import React from 'react';
import styled from 'styled-components';

import { base } from '@pagerland/common/src/utils';
import { colors } from '../../styles';
import logo from '../../assets/logo-transparent.png';

const Svg = styled.svg`
  display: block;
  ${base};
`;

const Logo = props => (
  <img style={{width: '120px'}} src={logo} alt="erdbau-siegl-logo" />
);

export default Logo;
