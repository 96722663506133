import React from 'react';

import WebGrid from '@pagerland/icons/src/line/WebGrid';
import BedDouble from '@pagerland/icons/src/line/BedDouble';
import Bath from '@pagerland/icons/src/line/Bath';
import CarSideview from '@pagerland/icons/src/line/CarSideview';
import Plane from '@pagerland/icons/src/line/Plane';
import TrainTunnel from '@pagerland/icons/src/line/TrainTunnel';
import ShoppingBag from '@pagerland/icons/src/line/ShoppingBag';
import Bus from '@pagerland/icons/src/line/Bus';
import Truck from '@pagerland/icons/src/line/Truck';
import Watch from '@pagerland/icons/src/line/WatchAlt';
import Trees from '@pagerland/icons/src/line/Trees';
import Flag from '@pagerland/icons/src/line/Flag';
import User from '@pagerland/icons/src/line/User';
import Building from '@pagerland/icons/src/line/Building';
import MapMarker from '@pagerland/icons/src/line/MapMarker';
import Phone from '@pagerland/icons/src/line/Phone';
import Envelope from '@pagerland/icons/src/line/Envelope';
import FacebookF from '@pagerland/icons/src/line/FacebookF';
import Instagram from '@pagerland/icons/src/line/Instagram';
import Cube from '@pagerland/icons/src/line/Cube';
import Bag from '@pagerland/icons/src/line/Bag';
import TagAlt from '@pagerland/icons/src/line/TagAlt';
import ThumbsUp from '@pagerland/icons/src/line/ThumbsUp';
import ShieldCheck from '@pagerland/icons/src/line/ShieldCheck';
import Award from '@pagerland/icons/src/line/Award';
import ClockTwo from '@pagerland/icons/src/line/ClockTwo';

import Icon from '@pagerland/common/src/components/Icon';

import textToMultiline from '@pagerland/common/src/utils/textToMultiline';

import * as Yup from 'yup';
import background from './assets/header-photo.jpg';
import background2x from './assets/header-photo@2x.jpg';

import about1 from './assets/about/about1.jpg';
import about2 from './assets/about/about2.jpg';
import about3 from './assets/about/about3.jpg';
import about1tn from './assets/about/thumbnails/about1.jpg';
import about2tn from './assets/about/thumbnails/about2.jpg';
import about3tn from './assets/about/thumbnails/about3.jpg';

import exterior1 from './assets/exteriors/ref1.jpg';
import exterior2 from './assets/exteriors/ref2.jpg';

import interior1 from './assets/interiors/interior-1.jpg';
import interior12x from './assets/interiors/interior-1@2x.jpg';
import interior2 from './assets/interiors/interior-2.jpg';
import interior22x from './assets/interiors/interior-2@2x.jpg';

import surrounding1 from './assets/surroundings/surroundings-1.jpg';
import surrounding12x from './assets/surroundings/surroundings-1@2x.jpg';
import surrounding2 from './assets/surroundings/surroundings-2.jpg';
import surrounding22x from './assets/surroundings/surroundings-2@2x.jpg';
import surrounding3 from './assets/surroundings/surroundings-3.jpg';
import surrounding32x from './assets/surroundings/surroundings-3@2x.jpg';

import foto1 from './assets/gallery/fotos-1/20221021_072045.jpg';
import foto2 from './assets/gallery/fotos-1/20221021_112410.jpg';
import foto3 from './assets/gallery/fotos-1/20221021_151309.jpg';
import foto4 from './assets/gallery/fotos-1/20221021_161200.jpg';
import foto5 from './assets/gallery/fotos-1/20221216_161110.jpg';
import foto1tn from './assets/gallery/fotos-1/thumbnails/20221021_072045.jpg';
import foto2tn from './assets/gallery/fotos-1/thumbnails/20221021_112410.jpg';
import foto3tn from './assets/gallery/fotos-1/thumbnails/20221021_151309.jpg';
import foto4tn from './assets/gallery/fotos-1/thumbnails/20221021_161200.jpg';
import foto5tn from './assets/gallery/fotos-1/thumbnails/20221216_161110.jpg';

import foto6 from './assets/gallery/fotos-2/20221004_143607.jpg';
import foto7 from './assets/gallery/fotos-2/20221004_150349.jpg';
import foto6tn from './assets/gallery/fotos-2/thumbnails/20221004_143607.jpg';
import foto7tn from './assets/gallery/fotos-2/thumbnails/20221004_150349.jpg';

import foto8 from './assets/gallery/fotos-3/20221017_163130.jpg';
import foto9 from './assets/gallery/fotos-3/20221017_163133.jpg';
import foto10 from './assets/gallery/fotos-3/20221019_085538.jpg';
import foto11 from './assets/gallery/fotos-3/20221020_091928.jpg';
import foto12 from './assets/gallery/fotos-3/20221020_091933.jpg';
import foto8tn from './assets/gallery/fotos-3/thumbnails/20221017_163130.jpg';
import foto9tn from './assets/gallery/fotos-3/thumbnails/20221017_163133.jpg';
import foto10tn from './assets/gallery/fotos-3/thumbnails/20221019_085538.jpg';
import foto11tn from './assets/gallery/fotos-3/thumbnails/20221020_091928.jpg';
import foto12tn from './assets/gallery/fotos-3/thumbnails/20221020_091933.jpg';

import foto13 from './assets/gallery/fotos-4/20220902_140331.jpg';
import foto14 from './assets/gallery/fotos-4/20220902_140432.jpg';
import foto15 from './assets/gallery/fotos-4/20220902_140511.jpg';
import foto16 from './assets/gallery/fotos-4/20220902_140541.jpg';
import foto17 from './assets/gallery/fotos-4/20220902_140550.jpg';
import foto18 from './assets/gallery/fotos-4/20220902_140601.jpg';
import foto19 from './assets/gallery/fotos-4/20220902_140613.jpg';
import foto13tn from './assets/gallery/fotos-4/thumbnails/20220902_140331.jpg';
import foto14tn from './assets/gallery/fotos-4/thumbnails/20220902_140432.jpg';
import foto15tn from './assets/gallery/fotos-4/thumbnails/20220902_140511.jpg';
import foto16tn from './assets/gallery/fotos-4/thumbnails/20220902_140541.jpg';
import foto17tn from './assets/gallery/fotos-4/thumbnails/20220902_140550.jpg';
import foto18tn from './assets/gallery/fotos-4/thumbnails/20220902_140601.jpg';
import foto19tn from './assets/gallery/fotos-4/thumbnails/20220902_140613.jpg';

import foto20 from './assets/gallery/fotos-5/20221006_102847.jpg';
import foto21 from './assets/gallery/fotos-5/20221006_102944.jpg';
import foto22 from './assets/gallery/fotos-5/20221006_103258.jpg';
import foto23 from './assets/gallery/fotos-5/20221006_103308.jpg';
import foto20tn from './assets/gallery/fotos-5/thumbnails/20221006_102847.jpg';
import foto21tn from './assets/gallery/fotos-5/thumbnails/20221006_102944.jpg';
import foto22tn from './assets/gallery/fotos-5/thumbnails/20221006_103258.jpg';
import foto23tn from './assets/gallery/fotos-5/thumbnails/20221006_103308.jpg';

import property1 from './assets/similar-properties/property-1.jpg';
import property12x from './assets/similar-properties/property-1@2x.jpg';
import property2 from './assets/similar-properties/property-2.jpg';
import property22x from './assets/similar-properties/property-2@2x.jpg';
import property3 from './assets/similar-properties/property-3.jpg';
import property32x from './assets/similar-properties/property-3@2x.jpg';

import contactImg from './assets/contact.jpg';

export default {
  title: 'Erdbau Siegl',
  navbar: {
    links: [
      {
        to: '',
        'data-to': '',
        label: 'Home',
      },
      {
        to: 'about',
        'data-to': 'about',
        label: 'Über uns',
      },
      {
        to: 'location',
        'data-to': 'location',
        label: 'Standort',
      },
      {
        to: 'exterior',
        'data-to': 'exterior',
        label: 'Referenzen',
      },
      {
        to: 'gallery',
        'data-to': 'gallery',
        label: 'Gallerie',
      },
      {
        to: 'contact',
        'data-to': 'contact',
        label: 'Kontakt',
      },
    ],
  },
  welcome: {
    background: {
      src: background,
      srcSet: `${background} 1x`,
      alt: 'Erdbau Siegl Foto',
    },
    title: 'Nicht verzagen Siegl fragen?',
    features: [
      {
        IconProps: {
          icon: WebGrid,
        },
        title: '5 800 sq. ft.',
      },
      {
        IconProps: {
          icon: BedDouble,
        },
        title: '7 bedrooms',
      },
      {
        IconProps: {
          icon: Bath,
        },
        title: '4 bathrooms',
      },
      {
        IconProps: {
          icon: CarSideview,
        },
        title: '2 cars garage',
      },
    ],
    cta: {
      to: 'about',
      children: 'Learn more',
    },
  },
  about: {
    title: 'Wir sind ein Familienunternehmen im Erdbau mit langjähriger Erfahrung und einem hervorragenden Ruf in der Branche.',
    text:
      'Unser Ziel ist es, jedem Kunden eine individuelle Lösung für sein Bauvorhaben zu bieten, die höchsten Qualitätsstandards entspricht. Wir setzen auf moderne Technologien und qualifizierte Mitarbeiter, um jeden Auftrag zuverlässig und termingerecht abzuschließen.',
    gallery: [
      { src: about1, srcThumbnail: about1tn },
      { src: about2, srcThumbnail: about2tn },
      { src: about3, srcThumbnail: about3tn },
    ],
  },
  location: {
    title: textToMultiline`Wir sind hauptsächlich im Burgenland/Niederösterreich tätig.`,
    text:
      `Unsere Arbeiten reichen von kleinen Hausbauprojekten bis hin zu großen industriellen Anlagen, für die wir individuelle Lösungen entwickeln. Wir legen großen Wert auf Nachhaltigkeit und Umweltbewusstsein in allen Phasen unserer Arbeiten.
      <br /><br />Diverse Arbeiten die wir erledigen:
      <ul>
        <li>Keller/Fundamentaushub</li>
        <li>Poolaushub</li>
        <li>Garten vorbereitungen</li>
        <li>Entsorgung Aushubmaterial</li>
        <li>Unterbau</li>
        <li>Wegebau</li>
        <li>Abbruch</li>
        <li>Aushub für Leitungsbau</li>
        <li>Rodungsarbeiten</li>
        <li>Kranarbeiten</li>
        <li>Natursteinmauer</li>
      </ul>
      `,
    counters: [
      {
        IconProps: {
          icon: Bag,
        },
        title: 'Erfahrung',
        counter: {
          value: 58,
          unit: ' Jahre',
        },
      },
      {
        IconProps: {
          icon: Cube,
        },
        title: 'Bewegte Erde',
        counter: {
          value: 23214,
          unit: 'm3',
        },
      },
      {
        IconProps: {
          icon: Truck,
        },
        title: 'Fahrzeugen',
        counter: {
          value: 10,
          unit: '',
        },
      },
      {
        IconProps: {
          icon: ClockTwo,
        },
        title: 'Arbeitsstunden',
        counter: {
          value: 176320,
          unit: '',
        },
      },
    ],
    map: {
      cords: {
        lat: 47.94799185108482,
        lng: 16.733312909045623,
      },
    },
  },
  exterior: {
    sections: [
      {
        ImgProps: {
          src: exterior1,
          srcSet: `${exterior1} 1x`,
        },
        title: 'Unsere zufriedenen Kunden sind das beste Zeugnis für unsere Arbeit.',
        text:
          'In den letzten Jahren haben wir erfolgreich an einer Vielzahl von Projekten im Erdbau gearbeitet, die wir stolz als Referenzen präsentieren.',
      },
      {
        ImgProps: {
          src: exterior2,
          srcSet: `${exterior2} 1x`,
        },
        title: 'Unsere Kunden schätzen uns für unsere hohen Qualitätsstandards.',
        text:
          'Unser Engagement und unsere Fähigkeit, Projekte termingerecht und innerhalb des Budgets abzuschließen. Kontaktieren Sie uns gerne, um eine Liste unserer Referenzen zu erhalten oder um mehr über unsere Arbeit zu erfahren.',
      },
    ],
  },
  interior: {
    sections: [
      {
        slides: [
          {
            src: interior2,
            srcSet: `${interior2} 1x, ${interior22x} 2x`,
          },
          {
            src: interior2,
            srcSet: `${interior2} 1x, ${interior22x} 2x`,
          },
          {
            src: interior2,
            srcSet: `${interior2} 1x, ${interior22x} 2x`,
          },
          {
            src: interior2,
            srcSet: `${interior2} 1x, ${interior22x} 2x`,
          },
          {
            src: interior2,
            srcSet: `${interior2} 1x, ${interior22x} 2x`,
          },
        ],
        title: 'Amazing living and dining room',
        text:
          'Vestibulum sed elit id mauris tempus feugiat vel vitae neque. Integer eleifend est orci, quis convallis sem malesuada id. Phasellus fringilla urna vitae lobortis auctor. Vivamus sed nisi non nulla tincidunt ultricies a non ex. Praesent commodo dui enim, at volutpat lectus aliquet ut.',
      },
      {
        slides: [
          {
            src: interior1,
            srcSet: `${interior1} 1x, ${interior12x} 2x`,
          },
          {
            src: interior1,
            srcSet: `${interior1} 1x, ${interior12x} 2x`,
          },
          {
            src: interior1,
            srcSet: `${interior1} 1x, ${interior12x} 2x`,
          },
          {
            src: interior1,
            srcSet: `${interior1} 1x, ${interior12x} 2x`,
          },
          {
            src: interior1,
            srcSet: `${interior1} 1x, ${interior12x} 2x`,
          },
        ],
        title: 'Beautiful bedrooms',
        text:
          'Vestibulum sed elit id mauris tempus feugiat vel vitae neque. Integer eleifend est orci, quis convallis sem malesuada id. Phasellus fringilla urna vitae lobortis auctor. Vivamus sed nisi non nulla tincidunt ultricies a non ex. Praesent commodo dui enim, at volutpat lectus aliquet ut.',
      },
    ],
  },
  surroundings: {
    sections: [
      {
        ImgProps: {
          src: surrounding1,
          srcSet: `${surrounding1} 1x, ${surrounding12x} 2x`,
        },
        title: 'Only few minutes driving to a shopping mall',
        text:
          'Curabitur porttitor ligula ut aliquam egestas. Fusce orci ipsum, gravida vel dapibus nec, interdum nec dui. Vestibulum sed porttitor est. Nullam sollicitudin, odio vel mattis bibendum, ligula nisl faucibus libero, vitae tempus dui orci in purus.',
        cta: {
          as: 'a',
          label: 'Learn more',
          href:
            'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        },
      },
      {
        ImgProps: {
          src: surrounding2,
          srcSet: `${surrounding2} 1x, ${surrounding22x} 2x`,
        },
        title: 'One of the best primary school and high school in the city',
        text:
          'Curabitur porttitor ligula ut aliquam egestas. Fusce orci ipsum, gravida vel dapibus nec, interdum nec dui. Vestibulum sed porttitor est. Nullam sollicitudin, odio vel mattis bibendum, ligula nisl faucibus libero, vitae tempus dui orci in purus.',
        cta: {
          as: 'a',
          label: 'Learn more',
          href:
            'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        },
      },
      {
        ImgProps: {
          src: surrounding3,
          srcSet: `${surrounding3} 1x, ${surrounding32x} 2x`,
        },
        title: 'Walking distance to the park with sport and recreation areas',
        text:
          'Curabitur porttitor ligula ut aliquam egestas. Fusce orci ipsum, gravida vel dapibus nec, interdum nec dui. Vestibulum sed porttitor est. Nullam sollicitudin, odio vel mattis bibendum, ligula nisl faucibus libero, vitae tempus dui orci in purus.',
        cta: {
          as: 'a',
          label: 'Learn more',
          href:
            'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        },
      },
    ],
  },
  gallery: {
    photos: [
      { src: foto1, srcThumbnail: foto1tn },
      { src: foto2, srcThumbnail: foto2tn },
      { src: foto3, srcThumbnail: foto3tn },
      { src: foto4, srcThumbnail: foto4tn },
      { src: foto5, srcThumbnail: foto5tn },
      { src: foto6, srcThumbnail: foto6tn },
      { src: foto7, srcThumbnail: foto7tn },
      { src: foto8, srcThumbnail: foto8tn },
      { src: foto9, srcThumbnail: foto9tn },
      { src: foto10, srcThumbnail: foto10tn },
      { src: foto11, srcThumbnail: foto11tn },
      { src: foto12, srcThumbnail: foto12tn },
      { src: foto13, srcThumbnail: foto13tn },
      { src: foto15, srcThumbnail: foto15tn },
      { src: foto16, srcThumbnail: foto16tn },
      { src: foto17, srcThumbnail: foto17tn },
      { src: foto18, srcThumbnail: foto18tn },
      { src: foto20, srcThumbnail: foto20tn },
      { src: foto21, srcThumbnail: foto21tn },
      { src: foto23, srcThumbnail: foto23tn },
    ],
  },
  contact: {
    title: 'Kontakt',
    thumbnail: {
      src: contactImg,
      srcSet: `${contactImg} 1x`,
    },
    details: {
      title: 'Erdbau Siegl',
      text:
        '',
      info: [
        {
          icon: User,
          text: 'Kevin Siegl',
        },
        {
          icon: MapMarker,
          text: textToMultiline`Prangerstraße 33,\n7091 Breitenbrunn, Austria`,
          href: 'https://www.google.com/maps/dir//Erdbau+Siegl,+Prangerstra%C3%9Fe,+Breitenbrunn,+Austria/@47.9463095,16.7311404,16.27z/data=!4m8!4m7!1m0!1m5!1m1!1s0x476c449957f1c719:0xc416dfff556410bb!2m2!1d16.7333348!2d47.9479856'
        },
        {
          icon: Phone,
          text: '+43 660 704 9388',
          href: 'tel:+436607049388'
        },
        {
          icon: Envelope,
          text: 'office@erdbau-siegl.at',
          href: 'mailto:office@erdbau-siegl.at'
        },
        {
          icon: ClockTwo,
          text: textToMultiline`Öffnungszeiten:\nMo - Fr 07:00-12:00/12:30-17:00`,
        },
      ],
      socialLinks: [
        {
          icon: FacebookF,
          href: 'https://www.facebook.com/erbewegungsiegl',
          title: 'Facebook',
        },
        {
          icon: Instagram,
          href: 'https://www.instagram.com/erdbau_siegl',
          title: 'Instagram',
        },
      ],
    },
    form: {
      title: 'Send me a message and I will call you back',
      sendButtonText: 'Send',
      validationSchema: Yup.object({
        firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
        email: Yup.string().email('Must be an email').required('Required'),
        message: Yup.string().min(20, 'Must be at least 20 characters').required('Required'),
      }),
      // eslint-disable-next-line no-undef
      onSubmit: values => window.alert(`Form sent with values ${JSON.stringify(values)}`),
      fields: [
        {
          name: 'firstName',
          label: 'First name',
          placeholder: 'ie. John Doe',
          initialValue: '',
          prefix: <Icon icon={User} />,
        },
        {
          name: 'phone',
          label: 'Phone',
          placeholder: 'ie. 555-678-123',
          initialValue: '',
          prefix: <Icon icon={Phone} />,
        },
        {
          name: 'email',
          label: 'E-mail',
          placeholder: 'ie. john.doe@email.com',
          type: 'email',
          initialValue: '',
          prefix: <Icon icon={Envelope} />,
        },
        {
          name: 'message',
          label: 'Message',
          placeholder: 'Start typing here...',
          multiline: true,
          initialValue: '',
        },
      ],
    },
  },
  similarProperties: {
    title: 'Similar properties',
    properties: [
      {
        ImgProps: {
          src: property1,
          srcSet: `${property1} 1x, ${property12x} 2x`,
        },
        LinkProps: {
          as: 'a',
          href:
            'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        },
        title: 'Villa with two terraces, swimming pool and beauitiful garden',
        price: '$3,450,000',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '3 400 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '5 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
      {
        ImgProps: {
          src: property2,
          srcSet: `${property2} 1x, ${property22x} 2x`,
        },
        LinkProps: {
          as: 'a',
          href:
            'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        },
        title: 'Classic spanish style house with amazin mountains view',
        price: '$2,780,000',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '4 200 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '6 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '4 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
      {
        ImgProps: {
          src: property3,
          srcSet: `${property3} 1x, ${property32x} 2x`,
        },
        LinkProps: {
          as: 'a',
          href:
            'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
        },
        title: 'Completely renovated ground floor villa with swimming pool',
        price: '$1,950,000',
        features: [
          {
            IconProps: {
              icon: WebGrid,
            },
            title: '2 800 sq. ft.',
          },
          {
            IconProps: {
              icon: BedDouble,
            },
            title: '4 bedrooms',
          },
          {
            IconProps: {
              icon: Bath,
            },
            title: '3 bathrooms',
          },
          {
            IconProps: {
              icon: CarSideview,
            },
            title: '2 cars garage',
          },
        ],
      },
    ],
  },
  footer: {
    title: 'About California Real Estates',
    text: textToMultiline`Cras sollicitudin erat sit amet egestas consequat. Quisque in purus sem. Integer condimentum nulla vel velit pretium, eget fringilla enim sodales. Nullam sit amet leo vitae mi laoreet varius eu vel est.\n\nMaecenas non lectus tincidunt, sodales leo pulvinar, condimentum urna. Fusce sed dui nec tortor tincidunt ultricies. Proin at convallis felis, sit amet varius velit.`,
    cta: {
      as: 'a',
      label: 'Learn more',
      href:
        'https://themeforest.net/item/pagerland-react-and-gatsby-landing-page-templates/26548567',
    },
    features: [
      {
        IconProps: {
          icon: TagAlt,
        },
        title: 'Best price on the market',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
      {
        IconProps: {
          icon: ThumbsUp,
        },
        title: 'Satisfaction guarantee',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
      {
        IconProps: {
          icon: ShieldCheck,
        },
        title: 'Safety policy',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
      {
        IconProps: {
          icon: Award,
        },
        title: 'Award winning company',
        text:
          'Proin at convallis felis, sit amet varius velit. Aenean placerat turpis a libero feugiat, at iaculis elit faucibus.',
      },
    ],
    socialLinks: [],
    links: [
      {
        href: 'impressum',
        label: 'Impressum'
      }
    ],
  },
  copyright: '© 2023 Erdbau Siegl',
};
