import React from 'react';
import PropTypes from 'prop-types';

import Fade from 'react-reveal/Fade';

import Box from '@pagerland/common/src/components/Box';
import Container from '@pagerland/common/src/components/Container';

import Typography from '@pagerland/common/src/components/Typography';
import Grid from '@pagerland/common/src/components/Grid';
import { Gallery, Item } from 'react-photoswipe-gallery';

import { StyledImg } from './styled.components';
import data from '../../data';

const About = ({
  name,
  title,
  text,
  gallery,
  WrapperProps,
  ContainerProps,
  CaptionProps,
  TitleProps,
  TextProps,
  GridProps,
  ImgProps,
}) => (
  <Box name={name} {...WrapperProps}>
    <Container {...ContainerProps}>
      <Box {...CaptionProps}>
        <Fade bottom cascade duration={600}>
          <Typography {...TitleProps}>{title}</Typography>
          <Typography {...TextProps}>{text}</Typography>
        </Fade>
        <Gallery options={{ shareEl: false }}>
          <Grid {...GridProps}>
            {gallery.map((item, i) => {
              return (
                <Item
                  original={item.src}
                  thumbnail={item.srcThumbnail}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <Fade key={i} bottom cascade duration={600} delay={i * 30}>
                      <StyledImg {...ImgProps} ref={ref} onClick={open} src={item.srcThumbnail} />
                    </Fade>
                  )}
                </Item>
              )
            })}
          </Grid>
        </Gallery>
      </Box>
    </Container>
  </Box>
);

About.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.node,
  text: PropTypes.node,
  WrapperProps: PropTypes.object,
  ContainerProps: PropTypes.object,
  CaptionProps: PropTypes.object,
  TitleProps: PropTypes.object,
  TextProps: PropTypes.object,
  GridProps: PropTypes.object,
  ImgProps: PropTypes.object,
};

About.defaultProps = {
  WrapperProps: {
    pt: {
      _: 80,
      md: 160,
    },
    pb: 80,
  },
  CaptionProps: {
    maxWidth: 736,
    mx: 'auto',
  },
  TitleProps: {
    accent: 'before',
    as: 'h2',
    variant: 'h2',
    mb: 4,
  },
  TextProps: {
    variant: 'body1',
    mb: 48,
    color: 'gray.3',
  },
  GridProps: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '1px',
  },
  ImgProps: {
    display: 'block',
    width: '100%',
  },
  ...data.about,
};

export default About;
